export const contactsData = {
    email: 'elaineelainefletcher@gmail.com',
    phone: '+85223306065    ',
    address: '6 Knutsford Ter, Tsim Sha Tsui, Hong Kong',
    github: 'https://github.com/tiger-eagle',
    facebook: 'https://www.facebook.com/abusaid.riyaz/',
    linkedIn: 'https://www.linkedin.com/in/abu-said-bd/',
    twitter: 'https://twitter.com/said7388',
    medium: 'https://medium.com/@abusaid-bd',
    stackOverflow: 'https://stackoverflow.com/users/16840768/abu-said',
    devUsername: "said7388"
}